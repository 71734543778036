function body(){
    return(
        <>
            <div style={{textAlign:'center'}}>

                <img class="title" src="/dokdo/title.png" alt="대한민국의 아름다운 영토 독도, 독도는 역사적, 지리적, 국제법적으로 명백한 우리 고유의 영토입니다." />
            </div>
            <h1>독도가 한국 땅인 이유</h1>
        </>
    )
}

export default body;